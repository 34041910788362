"use client";

import Button from "@/uicomponents/Button";
import useViewType from "@/utils/Hooks/useViewType";
import { usePathname, useRouter } from "next/navigation";
import { IBM_Plex_Sans, IBM_Plex_Sans_Condensed } from "next/font/google";
const metadata = {
  title: "GrowthSchool - Become the top 1%",
  description: "Whether it is Product, Growth, Design, Management, Tech & Data, Business, GrowthSchool is the place to learn from top experts in the field."
};
export const ibm_plex_sans_init = IBM_Plex_Sans({
  subsets: ["latin"],
  weight: ["300", "400", "500", "600", "700"],
  variable: "--font-ibm-plex-sans"
});
export const ibm_plax_sans_condensed = IBM_Plex_Sans_Condensed({
  subsets: ["latin"],
  weight: ["300", "400", "500", "600", "700"],
  variable: "--font-ibm-plex-sans-condensed"
});
export default function NotFound() {
  const isMobile = useViewType();
  const {
    push
  } = useRouter();
  const path = usePathname();
  const redirectUrl: any = {
    "/apply-as-mentor": "https://home.growthschool.io/apply-as-mentor",
    "/b2b": "https://home.growthschool.io/b2b",
    "/learners-center": "https://home.growthschool.io/learners-center",
    "/mentors": "https://home.growthschool.io/mentors",
    "/newsletter-vault": "https://home.growthschool.io/newsletter-vault",
    "/wall-of-love": "https://home.growthschool.io/wall-of-love",
    "/web3-application": "https://home.growthschool.io/web3-application",
    "/linkedin-workshop": "https://home.growthschool.io/linkedin-workshop",
    "/lifetime-deal": "https://home.growthschool.io/lifetime-deal",
    "/careers": "https://home.growthschool.io/careers",
    "/lc/marketing": "https://home.growthschool.io/lc/marketing"
  };
  if (path.includes("/ai") || path.includes("/ai/")) {
    push("https://applications.growthschool.io/292/1207?utm_source=OTT&utm_medium=Hotstar&utm_campaign=WCC2024&utm_adset=topcities&utm_content=aitakingover&utm_term=20062024");
    return;
  }
  if (redirectUrl[path] || path.includes("/newsletter/") || path.includes("/articles/") || path.includes("/programs/") || path.includes("/mentors/") || path.includes("/categories/") || path.includes("/author/") || path.includes("/topics/") || path.includes("/crash-courses/") || path.includes("/industry/")) {
    push(`https://home.growthschool.io${path}`);
    return;
  }
  return <>
      <div>
        <div>
          <meta name="robots" content="noindex" data-sentry-element="meta" data-sentry-source-file="not-found.tsx"></meta>
          <meta name="googlebot" content="noindex" data-sentry-element="meta" data-sentry-source-file="not-found.tsx"></meta>
          <meta name="title" content={metadata.title} data-sentry-element="meta" data-sentry-source-file="not-found.tsx" />
          <meta name="description" content={metadata.description} data-sentry-element="meta" data-sentry-source-file="not-found.tsx" />
          <meta property="og:title" content={metadata.title} data-sentry-element="meta" data-sentry-source-file="not-found.tsx" />
          <meta property="og:description" content={metadata.description} data-sentry-element="meta" data-sentry-source-file="not-found.tsx" />
          <title>{metadata.title}</title>
          <link href="/favicon-new.svg" rel="icon" type="image/x-icon" />
        </div>

        <div className="relative h-screen bg-cover bg-center bg-[#002726] m-5 md:mx-20 rounded-3xl bg-[url('/public/images/not-found-Grid.png')]">
          <div className="absolute inset-0 flex items-center justify-center">
            {/* Card Container */}
            <div className="flex flex-col md:flex-row sm:bg-[#001414] sm:bg-[url('/path/to/card-image.png')] bg-no-repeat bg-center bg-cover p-8 rounded-lg shadow-lg w-[830px] h-[297px]">
              {/* 404 Section */}
              <div className="flex flex-col items-center justify-center mb-6 md:mb-0 md:mr-6">
                <div className="bg-[url('/public/images/404.png')] bg-no-repeat bg-center bg-cover mb-6" style={{
                height: "100px",
                width: "206px"
              }} // Adjust the dimensions
              />
                <h2 className="text-white text-center mt-4">Page Not Found</h2>
              </div>
              {/* Divider */}
              <div className="hidden md:block w-[1px] bg-white mx-6"></div>{" "}
              {/* Vertical Divider on larger screens */}
              <div className="block md:hidden h-[1px] bg-white my-6"></div>{" "}
              {/* Horizontal Divider on smaller screens */}
              {/* Text and Button Section */}
              <div className="flex flex-col justify-center max-w-md md:ml-6">
                <p className="text-lg text-white mb-6 text-left">
                  Oops! This page doesn't exist... but while you're here, how
                  about a fun fact?
                </p>
                <br />
                <p className="text-lg text-white mb-6 text-left">
                  “The QWERTY keyboard layout was designed to slow typists down,
                  not to make typing faster”
                </p>
                <div className="flex justify-center mt-6 md:justify-start">
                  <Button text="Back to Home" variant="NormalVariant" textVariant={isMobile ? "Mobile/Body 2/M | 14 | 120" : "Desktop/Body 1/M | 16 |120"} textColor="#002726" backgroundColor="#FFFAF1" clickHandler={() => push("/")} data-sentry-element="Button" data-sentry-source-file="not-found.tsx" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>;
}